export enum CompetitionCMSSteps {
    General = 'general',
    Tournament = 'tournament',
    Registration = 'registration',
    Chapters = 'chapters',
    Details = 'details',
    Admin = 'admin',
}


export const CompetitionCMSStepsArray = [
    CompetitionCMSSteps.General,
    CompetitionCMSSteps.Tournament,
    CompetitionCMSSteps.Registration,
    CompetitionCMSSteps.Details,
];

export const CompetitionCMSStepsArrayAdmin = [
    CompetitionCMSSteps.General,
    CompetitionCMSSteps.Tournament,
    CompetitionCMSSteps.Registration,
    CompetitionCMSSteps.Details,
    CompetitionCMSSteps.Admin,
];

export const CompetitionCMSCourseStepsArray = [
    CompetitionCMSSteps.General,
    CompetitionCMSSteps.Chapters,
    CompetitionCMSSteps.Registration,
    CompetitionCMSSteps.Details,
];
